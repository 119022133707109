import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Box,
  Button,
  HStack,
  Side,
  Spacer,
  Subheader,
  Switch,
  Text,
  Widget,
  VStack,
  StatusPopup,
} from '@revolut/ui-kit'
import SideBar from '@src/components/SideBar/SideBar'
import SendEmailCommon from '@src/pages/Forms/SendEmail/SendEmailCommon'
import Form from '@src/features/Form/Form'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { SendCandidateEmailInterface } from '@src/interfaces/hiringProccess'
import { sendOffer } from '@src/api/offerCreation'
import { candidateEmailsRequests } from '@src/api/hiringProcess'
import { pathToUrl } from '@src/utils/router'
import { navigateTo } from '@src/actions/RouterActions'
import { ROUTES } from '@src/constants/routes'
import useGetPrefilledPlaceholdersEmail from '@src/pages/Forms/SendEmail/useGetPrefilledPlaceholdersEmail'
import { useGetOfferSettings } from '@src/api/settings'
import { FileInterface } from '@src/interfaces/files'
import UploadedFile from '@src/components/Inputs/FileUploader/UploadedFile'
import { useShowStatusPopup } from '@src/utils/useShowStatusPopup'
import { getStringMessageFromError } from '@src/store/notifications/actions'
import { useSelector } from 'react-redux'
import { selectUser } from '@src/store/auth/selectors'

type Props = {
  offerFile?: FileInterface
  onRefresh: () => void
}

const SendOfferAndEmailSidebar = ({ offerFile, onRefresh }: Props) => {
  const { candidateId, id: offerId } = useParams<{ candidateId: string; id: string }>()
  const user = useSelector(selectUser)
  const { values, submit } = useLapeContext<SendCandidateEmailInterface>()
  const [sendEmail, setSendEmail] = useState(true)
  const [loading, setLoading] = useState(false)
  const [attachOfferFile, setAttachOfferFile] = useState(true)
  const { data: offerSettings } = useGetOfferSettings()
  const { loading: loadingFetchEmail, fetchEmail: getPrefilledPlaceholdersEmail } =
    useGetPrefilledPlaceholdersEmail()
  const showStatusPopup = useShowStatusPopup()

  const fetchEmail = (templateId: number) => {
    return getPrefilledPlaceholdersEmail(templateId, +candidateId)
  }

  const offerSigningEnabled = !!offerSettings?.enable_offer_signing
  const shouldAttachOfferFile = !offerSigningEnabled && !!offerFile && attachOfferFile

  const showEmailSuccess = () => {
    showStatusPopup({
      title:
        shouldAttachOfferFile || (offerSigningEnabled && sendEmail)
          ? 'Your email and offer are sent to the candidate'
          : 'Your email was sent to the candidate',
      onClose,
    })
  }
  const showEmailFail = (description: string) => {
    const integrationError =
      description.indexOf('Google Email integration not enabled') !== -1
    showStatusPopup({
      title: 'Something went wrong',
      description: (
        <>
          {offerSigningEnabled && 'Offer was sent but something went wrong sending email'}
          <br />
          {description}
        </>
      ),
      status: 'error',
      actions: statusPopup => (
        <StatusPopup.Actions>
          {integrationError ? (
            <Button
              onClick={() => {
                statusPopup.hide()
                navigateTo(
                  pathToUrl(ROUTES.FORMS.EMPLOYEE.LINKED_ACCOUNTS, {
                    id: user.id,
                  }),
                )
              }}
            >
              Enable email integration
            </Button>
          ) : (
            <>
              <Button
                onClick={() => {
                  statusPopup.hide()
                  onClose()
                }}
              >
                Return to main page
              </Button>
              <Button
                variant="secondary"
                onClick={() => {
                  statusPopup.hide()
                  navigateTo(
                    pathToUrl(ROUTES.FORMS.CANDIDATE.EMAILS, {
                      id: candidateId,
                    }),
                  )
                }}
              >
                Navigate to send email
              </Button>
            </>
          )}
        </StatusPopup.Actions>
      ),
      onClose,
    })
  }
  const showOfferSuccess = () => {
    showStatusPopup({
      title: 'Offer sent to candidate via docusign',
      onClose,
    })
  }
  const showOfferFail = (description: string) => {
    showStatusPopup({
      title: 'Something went wrong',
      description,
      status: 'error',
    })
  }

  const onSend = async () => {
    const { attachments } = values
    setLoading(true)
    try {
      if (offerSigningEnabled) {
        await sendOffer(+offerId)
      }
      if (sendEmail) {
        if (shouldAttachOfferFile) {
          values.attachments = [...(values.attachments || []), offerFile]
        }
        try {
          await submit()
          showEmailSuccess()
        } catch (e) {
          showEmailFail(getStringMessageFromError(e))
        }
      } else {
        showOfferSuccess()
      }
    } catch (e) {
      showOfferFail(getStringMessageFromError(e))
    } finally {
      values.attachments = attachments
      setLoading(false)
    }
  }

  const onClose = () => {
    onRefresh()
  }

  return (
    <>
      <SideBar
        variant="wide"
        title={
          offerSettings?.enable_offer_signing
            ? 'Send offer & email to candidate'
            : 'Send email and attach an offer document'
        }
        subtitle={
          offerSettings?.enable_offer_signing
            ? 'Offer will be sent via DocuSign in an outstanding email. You can send an additional email to the candidate and congratulate them.'
            : 'Send an email congratulating the candidate and explaining the offer details. Make sure that the offer is attached to the email. If it’s not attached automatically – you can download the offer  using “Download (docx)” button at the bottom of this page'
        }
        onClose={onClose}
      >
        {offerSettings?.enable_offer_signing && (
          <>
            <Box>
              <Subheader variant="nested">
                <Subheader.Title>Additional email</Subheader.Title>
              </Subheader>
            </Box>
            <Widget p="s-16" mb="s-16">
              <HStack use="label">
                <Text>Do you want to send an additional email to candidate?</Text>
                <Spacer />
                <Switch
                  value="showEmailBody"
                  checked={sendEmail}
                  onChange={() => setSendEmail(!sendEmail)}
                />
              </HStack>
            </Widget>
          </>
        )}
        {sendEmail && (
          <VStack gap="s-16">
            <SendEmailCommon fetchEmail={fetchEmail} insideSidebar actions={null} />
            {shouldAttachOfferFile && (
              <UploadedFile
                size={offerFile.size}
                title={offerFile.name}
                onDelete={() => {
                  setAttachOfferFile(false)
                }}
              />
            )}
          </VStack>
        )}
        <Side.Actions horizontal>
          <Button onClick={onSend} disabled={loading || loadingFetchEmail}>
            Send
          </Button>
        </Side.Actions>
      </SideBar>
    </>
  )
}

const SendOfferAndEmailSidebarForm = ({ offerFile, onRefresh }: Props) => {
  const { candidateId } = useParams<{ candidateId: string }>()
  return (
    <Form
      api={candidateEmailsRequests}
      forceParams={{
        new: 'new',
        candidateId,
      }}
      disableLocalStorageCaching
    >
      <SendOfferAndEmailSidebar offerFile={offerFile} onRefresh={onRefresh} />
    </Form>
  )
}

export default SendOfferAndEmailSidebarForm

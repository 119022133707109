import React from 'react'
import { selectorKeys } from '@src/constants/api'
import { CellTypes, ColumnInterface, FilterType } from '@src/interfaces/data'
import { EmployeeTotalCompensationGrantInterface } from '@src/interfaces/totalCompensation'
import { GrantActions } from '@src/components/ColumnInserts/GrantActions/GrantActions'
import UserWithAvatar from '@src/components/UserWithAvatar/UserWithAvatar'

export const grantIdColumn: ColumnInterface<EmployeeTotalCompensationGrantInterface> = {
  type: CellTypes.text,
  idPoint: 'external_id',
  dataPoint: 'external_id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'ID',
}

export const grantDateColumn: ColumnInterface<EmployeeTotalCompensationGrantInterface> = {
  type: CellTypes.date,
  idPoint: 'effective_date',
  dataPoint: 'effective_date',
  sortKey: 'effective_date',
  filterKey: 'effective_date',
  filterType: FilterType.shortDate,
  selectorsKey: selectorKeys.none,
  title: 'Grant date',
}

export const grantReasonColumn: ColumnInterface<EmployeeTotalCompensationGrantInterface> =
  {
    type: CellTypes.text,
    idPoint: 'reason',
    dataPoint: 'reason',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Reason',
  }

export const grantScheduleColumn: ColumnInterface<EmployeeTotalCompensationGrantInterface> =
  {
    type: CellTypes.text,
    idPoint: 'vesting_schedule_description',
    dataPoint: 'vesting_schedule_description',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Schedule',
  }

export const grantStartDateColumn: ColumnInterface<EmployeeTotalCompensationGrantInterface> =
  {
    type: CellTypes.date,
    idPoint: 'vesting_start_date',
    dataPoint: 'vesting_start_date',
    sortKey: 'vesting_start_date',
    filterKey: 'vesting_start_date',
    filterType: FilterType.shortDate,
    selectorsKey: selectorKeys.none,
    title: 'Start date',
  }

export const grantGrantedSharesColumn: ColumnInterface<EmployeeTotalCompensationGrantInterface> =
  {
    type: CellTypes.text,
    idPoint: 'total_quantity',
    dataPoint: 'total_quantity',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Granted shares',
  }

export const grantVestedSharesColumn: ColumnInterface<EmployeeTotalCompensationGrantInterface> =
  {
    type: CellTypes.text,
    idPoint: 'vested_quantity',
    dataPoint: 'vested_quantity',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Vested shares',
  }

export const grantUnvestedSharesColumn: ColumnInterface<EmployeeTotalCompensationGrantInterface> =
  {
    type: CellTypes.text,
    idPoint: 'unvested_quantity',
    dataPoint: 'unvested_quantity',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Unvested shares',
  }

export const grantCancelledSharesColumn: ColumnInterface<EmployeeTotalCompensationGrantInterface> =
  {
    type: CellTypes.text,
    idPoint: 'cancelled_quantity',
    dataPoint: 'cancelled_quantity',
    sortKey: null,
    filterKey: null,
    selectorsKey: selectorKeys.none,
    title: 'Cancelled shares',
  }

export const grantEmployeeColumn: ColumnInterface<EmployeeTotalCompensationGrantInterface> =
  {
    type: CellTypes.insert,
    idPoint: 'employee',
    dataPoint: 'employee',
    sortKey: 'employee__full_name',
    filterKey: 'employee_id',
    selectorsKey: selectorKeys.employee,
    title: 'Employee',
    insert: ({ data }) => <UserWithAvatar {...data.employee} />,
  }

export const grantActionsColumn = (
  refresh: VoidFunction,
): ColumnInterface<EmployeeTotalCompensationGrantInterface> => ({
  type: CellTypes.insert,
  idPoint: 'id',
  dataPoint: 'id',
  sortKey: null,
  filterKey: null,
  selectorsKey: selectorKeys.none,
  title: 'Actions',
  insert: ({ data }) => <GrantActions id={data.id} onDelete={refresh} />,
})
